import React from "react";

import { lostDE, lostEN, lostES } from "../template";

const YouAreLost = (props) => {
  const htmlLost =
    props.lang === "de" ? lostDE : props.lang === "en" ? lostEN : lostES;

  return <div dangerouslySetInnerHTML={{ __html: htmlLost }} />;
};

export default YouAreLost;
