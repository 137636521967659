import React from "react";
import { authRef } from "../reference";
import { verifyNoDE, verifyNoEN, verifyYesDE, verifyYesEN } from "../template";

const VerifyEmail = (props) => {
  const [validCode, setValidCode] = React.useState(false);
  const [verifiedCode, setVerifiedCode] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  React.useEffect(() => {
    // Try to apply the email verification code.
    authRef.languageCode = props.lang;
    if (props?.actionCode !== null)
      authRef.applyActionCode(props.actionCode).then(
        () => {
          // Email address has been verified.
          setValidCode(true);
          setVerifiedCode(true);
        },
        (error) => {
          // Code is invalid or expired. Ask the user to verify their email address
          // again.
          setErrorMessage(error.message);
          console.log("error", error);
        }
      );
  }, []);

  const htmlNo = props.lang === "de" ? verifyNoDE : verifyNoEN;

  const htmlYes = props.lang === "de" ? verifyYesDE : verifyYesEN;

  if (verifiedCode && validCode) {
    return <div dangerouslySetInnerHTML={{ __html: htmlYes }} />;
  } else {
    return <div dangerouslySetInnerHTML={{ __html: htmlNo }} />;
  }
};

export default VerifyEmail;
