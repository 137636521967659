export const lostDE = `<!DOCTYPE html>
<html lang="de">
<head>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta name="color-scheme" content="light only">
    <meta name="supported-color-schemes" content="light only">
    <link rel="preconnect" href="https://fonts.gstatic.com">
    <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap" rel="stylesheet">
    <title>HAPAHAPA | auth.hapahapa.app</title>

    <style type="text/css" media="screen">
    
        :root {
        color-scheme: light only;
        supported-color-schemes: light only;
        }

        body { 
            padding:0 !important; 
            margin: 0 auto !important;
            display:block !important; 
            min-width:100% !important; 
            width: 100% !important; 
              background: url('https://www.hapahapa.app/emails/bg_email.jpg');
              background-size: auto;
             font-family: "Montserrat", sans-serif;
        }

        h1 {
            font-size: 30px;
            line-height: 40px;
            font-weight: 700;
        }


        p {
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            margin: 0px;
            padding: 0px;
        }

        a {
            color:  #000;
        }


        a.white {
            font-size: 14px;
            color:  #FFF;
    
        }


        .content-table {
            width: 80%;
            height: 100%;
            border: 0;
               border-spacing: 0px; 
            padding: 0px;
        }


        .tr-white {
            background-color: #FFF;
            padding:0px;
        }


        .td-logo {
            padding-top: 40px;
            padding-bottom: 40px;
        }


        .td-first {
            padding-top: 20px;
            padding-left: 40px;
            padding-right: 40px;
            padding-bottom: 40px
        }


        .text-white {
            color:  #FFF;
        }

                
        /* Mobile styles */
        @media only screen and (max-device-width: 480px), only screen and (max-width: 480px) {
        
        .content-table {
            width: 90%;
            height: 100%;
            border: 0;
               border-spacing: 0px; 
            padding: 0px;

        }

        .td-logo {
            padding-top: 30px;
            padding-bottom: 30px;
        }

        .td-first {
            padding-top: 10px;
            padding-left: 20px;
            padding-right: 20px;
            padding-bottom: 40px
        }



        }


    </style>

</head>
<body class="body">
    
    <!-- WRAPPER -->
    <table width="100%" cellspacing="0" cellpadding="0">
        <tr>
            <td align="center" valign="top">
                
                <!-- CONTENT TABLE -->
                <table class="content-table">
                    <tr>
                        <td class="td-logo" align="center" valign="top">
                            <img src="https://www.hapahapa.app/emails/logo_hapahapa_text.svg">
                        </td>
                    </tr>
                    <tr class="tr-white">
                        <td class="td-first" align="left" valign="top">
                            <h1>You are lost</h1>
                            <p>It's time to go back to <a href="https://hapahapa.app">HAPAHAPA</a>
                            </p>
                        </td>
                    </tr>

                    <tr>
                        <td align="left" valign="top" style="padding-top: 10px; padding-bottom: 40px;">
                            <p class="text-white"><a class="white" href="https://hapahapa.app">© HAPAHAPA</a> | <a class="white" href="#">Impressum</a> | <a class="white" href="#">Datenschutz</a></p>
                        </td>
                    </tr>

                </table>

            </td>
        </tr>
    </table>

</body>
</html>
`;
export const lostES = `<!DOCTYPE html>
<html lang="es">
<head>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta name="color-scheme" content="light only">
    <meta name="supported-color-schemes" content="light only">
    <link rel="preconnect" href="https://fonts.gstatic.com">
    <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap" rel="stylesheet">
    <title>HAPAHAPA | auth.hapahapa.app</title>

    <style type="text/css" media="screen">
    
        :root {
        color-scheme: light only;
        supported-color-schemes: light only;
        }

        body { 
            padding:0 !important; 
            margin: 0 auto !important;
            display:block !important; 
            min-width:100% !important; 
            width: 100% !important; 
              background: url('https://www.hapahapa.app/emails/bg_email.jpg');
              background-size: auto;
             font-family: "Montserrat", sans-serif;
        }

        h1 {
            font-size: 30px;
            line-height: 40px;
            font-weight: 700;
        }


        p {
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            margin: 0px;
            padding: 0px;
        }

        a {
            color:  #000;
        }


        a.white {
            font-size: 14px;
            color:  #FFF;
    
        }


        .content-table {
            width: 80%;
            height: 100%;
            border: 0;
               border-spacing: 0px; 
            padding: 0px;
        }


        .tr-white {
            background-color: #FFF;
            padding:0px;
        }


        .td-logo {
            padding-top: 40px;
            padding-bottom: 40px;
        }


        .td-first {
            padding-top: 20px;
            padding-left: 40px;
            padding-right: 40px;
            padding-bottom: 40px
        }


        .text-white {
            color:  #FFF;
        }

                
        /* Mobile styles */
        @media only screen and (max-device-width: 480px), only screen and (max-width: 480px) {
        
        .content-table {
            width: 90%;
            height: 100%;
            border: 0;
               border-spacing: 0px; 
            padding: 0px;

        }

        .td-logo {
            padding-top: 30px;
            padding-bottom: 30px;
        }

        .td-first {
            padding-top: 10px;
            padding-left: 20px;
            padding-right: 20px;
            padding-bottom: 40px
        }



        }


    </style>

</head>
<body class="body">
    
    <!-- WRAPPER -->
    <table width="100%" cellspacing="0" cellpadding="0">
        <tr>
            <td align="center" valign="top">
                
                <!-- CONTENT TABLE -->
                <table class="content-table">
                    <tr>
                        <td class="td-logo" align="center" valign="top">
                            <img src="https://www.hapahapa.app/emails/logo_hapahapa_text.svg">
                        </td>
                    </tr>
                    <tr class="tr-white">
                        <td class="td-first" align="left" valign="top">
                            <h1>Yestás perdido</h1>
                            <p>es hora de volver a <a href="https://hapahapa.app">HAPAHAPA</a>
                            </p>
                        </td>
                    </tr>

                    <tr>
                        <td align="left" valign="top" style="padding-top: 10px; padding-bottom: 40px;">
                            <p class="text-white"><a class="white" href="https://hapahapa.app">© HAPAHAPA</a> | <a class="white" href="#">Impressum</a> | <a class="white" href="#">Datenschutz</a></p>
                        </td>
                    </tr>

                </table>

            </td>
        </tr>
    </table>

</body>
</html>
`;
export const lostEN = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta name="color-scheme" content="light only">
    <meta name="supported-color-schemes" content="light only">
    <link rel="preconnect" href="https://fonts.gstatic.com">
    <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap" rel="stylesheet">
    <title>HAPAHAPA | auth.hapahapa.app</title>

    <style type="text/css" media="screen">
    
        :root {
        color-scheme: light only;
        supported-color-schemes: light only;
        }

        body { 
            padding:0 !important; 
            margin: 0 auto !important;
            display:block !important; 
            min-width:100% !important; 
            width: 100% !important; 
              background: url('https://www.hapahapa.app/emails/bg_email.jpg');
              background-size: auto;
             font-family: "Montserrat", sans-serif;
        }

        h1 {
            font-size: 30px;
            line-height: 40px;
            font-weight: 700;
        }


        p {
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            margin: 0px;
            padding: 0px;
        }

        a {
            color:  #000;
        }


        a.white {
            font-size: 14px;
            color:  #FFF;
    
        }


        .content-table {
            width: 80%;
            height: 100%;
            border: 0;
               border-spacing: 0px; 
            padding: 0px;
        }


        .tr-white {
            background-color: #FFF;
            padding:0px;
        }


        .td-logo {
            padding-top: 40px;
            padding-bottom: 40px;
        }


        .td-first {
            padding-top: 20px;
            padding-left: 40px;
            padding-right: 40px;
            padding-bottom: 40px
        }


        .text-white {
            color:  #FFF;
        }

                
        /* Mobile styles */
        @media only screen and (max-device-width: 480px), only screen and (max-width: 480px) {
        
        .content-table {
            width: 90%;
            height: 100%;
            border: 0;
               border-spacing: 0px; 
            padding: 0px;

        }

        .td-logo {
            padding-top: 30px;
            padding-bottom: 30px;
        }

        .td-first {
            padding-top: 10px;
            padding-left: 20px;
            padding-right: 20px;
            padding-bottom: 40px
        }



        }


    </style>

</head>
<body class="body">
    
    <!-- WRAPPER -->
    <table width="100%" cellspacing="0" cellpadding="0">
        <tr>
            <td align="center" valign="top">
                
                <!-- CONTENT TABLE -->
                <table class="content-table">
                    <tr>
                        <td class="td-logo" align="center" valign="top">
                            <img src="https://www.hapahapa.app/emails/logo_hapahapa_text.svg">
                        </td>
                    </tr>
                    <tr class="tr-white">
                        <td class="td-first" align="left" valign="top">
                            <h1>You are lost</h1>
                            <p>It's time to go back to  <a href="https://hapahapa.app">HAPAHAPA</a>
                            </p>
                        </td>
                    </tr>

                    <tr>
                        <td align="left" valign="top" style="padding-top: 10px; padding-bottom: 40px;">
                            <p class="text-white"><a class="white" href="https://hapahapa.app">© HAPAHAPA</a> | <a class="white" href="#">Impressum</a> | <a class="white" href="#">Datenschutz</a></p>
                        </td>
                    </tr>

                </table>

            </td>
        </tr>
    </table>

</body>
</html>
`;
export const verifyNoDE = `<!DOCTYPE html>
<html lang="de">
<head>
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <meta name="color-scheme" content="light only">
  <meta name="supported-color-schemes" content="light only">
  <link rel="preconnect" href="https://fonts.gstatic.com">
  <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap" rel="stylesheet">
  <title>HAPAHAPA | E-Mail Verifizierung<</title>

  <style type="text/css" media="screen">

    :root {
    color-scheme: light only;
    supported-color-schemes: light only;
    }


    body { 
      padding:0 !important; 
        margin: 0 auto !important;
      display:block !important; 
      min-width:100% !important; 
      width: 100% !important; 
        background: url('https://www.hapahapa.app/emails/bg_email.jpg');
        background-size: auto;
         font-family: "Montserrat", sans-serif;
    }

    h1 {
        font-size: 30px;
        line-height: 40px;
        font-weight: 700;
    }


    p {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        margin: 0px;
        padding: 0px;
    }

    a {
      color:  #000;
    }


    a.white {
        font-size: 14px;
      color:  #FFF;
  
    }


    .content-table {
      width: 80%;
      height: 100%;
      border: 0;
         border-spacing: 0px; 
      padding: 0px;
    }


    .tr-white {
      background-color: #FFF;
      padding:0px;
    }


    .td-logo {
      padding-top: 40px;
      padding-bottom: 40px;
    }


    .td-first {
      padding-top: 20px;
      padding-left: 40px;
      padding-right: 40px;
    }


    .td-second {
      padding-top: 20px; 
      padding-left: 40px; 
      padding-right: 40px; 
      padding-bottom: 40px
    }

    .text-white {
      color:  #FFF;
    }

    .cta-orange {
        -webkit-transition-duration: 0.4s; /* Safari */
        transition-duration: 0.4s;
        background-color: #FF7600;
        padding-top: 18px;
        padding-left: 30px;
        padding-bottom: 18px;
        padding-right: 30px;
        color:  #FFF;
        line-height: 24px;
        letter-spacing: 1px;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 18px;
        font-weight: 700;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #FF7600;

    }

        
    /* Mobile styles */
    @media only screen and (max-device-width: 480px), only screen and (max-width: 480px) {
    
    .content-table {
      width: 90%;
      height: 100%;
      border: 0;
         border-spacing: 0px; 
      padding: 0px;

    }

    .td-logo {
      padding-top: 30px;
      padding-bottom: 30px;
    }

    .td-first {
      padding-top: 10px;
      padding-left: 20px;
      padding-right: 20px;
    }

    .td-second {
      padding-top: 20px; 
      padding-left: 20px; 
      padding-right: 20px; 
      padding-bottom: 40px
    }


    }


  </style>

</head>
<body class="body">
  
  <table width="100%" cellspacing="0" cellpadding="0">
    <tr>
      <td align="center" valign="top">
        

        <table class="content-table">
          <tr>
            <td class="td-logo" align="center" valign="top">
              <img src="https://www.hapahapa.app/emails/logo_hapahapa_text.svg">
            </td>
          </tr>
          <tr class="tr-white">
            <td class="td-first" align="left" valign="top">
              <h1>HAPAHAPA | E-Mail Verifizierung</h1>
              <p style="margin-bottom: 6px;"><strong></strong></p>
              <p>Hallo! Dein Token ist leider abgelaufen, versuche es bitte noch einmal...
              </p>
            </td>
          </tr>

          <tr>
            <td align="left" valign="top" style="padding-top: 10px; padding-bottom: 40px;">
              <p class="text-white"><a class="white" href="https://hapahapa.app">© HAPAHAPA</a> | <a class="white" href="#">Impressum</a> | <a class="white" href="#">Datenschutz</a></p>
            </td>
          </tr>

        </table>

      </td>
    </tr>
  </table>

</body>
</html>`;
export const verifyNoEN = `<!DOCTYPE html>
<html lang="en">
<head>
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <meta name="color-scheme" content="light only">
  <meta name="supported-color-schemes" content="light only">
  <link rel="preconnect" href="https://fonts.gstatic.com">
  <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap" rel="stylesheet">
  <title>HAPAHAPA | E-Mail Verification</title>

  <style type="text/css" media="screen">

    :root {
    color-scheme: light only;
    supported-color-schemes: light only;
    }

    body { 
      padding:0 !important; 
        margin: 0 auto !important;
      display:block !important; 
      min-width:100% !important; 
      width: 100% !important; 
        background: url('https://www.hapahapa.app/emails/bg_email.jpg');
        background-size: auto;
         font-family: "Montserrat", sans-serif;
    }

    h1 {
        font-size: 30px;
        line-height: 40px;
        font-weight: 700;
    }


    p {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        margin: 0px;
        padding: 0px;
    }

    a {
      color:  #000;
    }


    a.white {
        font-size: 14px;
      color:  #FFF;
  
    }


    .content-table {
      width: 80%;
      height: 100%;
      border: 0;
         border-spacing: 0px; 
      padding: 0px;
    }


    .tr-white {
      background-color: #FFF;
      padding:0px;
    }


    .td-logo {
      padding-top: 40px;
      padding-bottom: 40px;
    }


    .td-first {
      padding-top: 20px;
      padding-left: 40px;
      padding-right: 40px;
    }


    .td-second {
      padding-top: 20px; 
      padding-left: 40px; 
      padding-right: 40px; 
      padding-bottom: 40px
    }

    .text-white {
      color:  #FFF;
    }

    .cta-orange {
        -webkit-transition-duration: 0.4s; /* Safari */
        transition-duration: 0.4s;
        background-color: #FF7600;
        padding-top: 18px;
        padding-left: 30px;
        padding-bottom: 18px;
        padding-right: 30px;
        color:  #FFF;
        line-height: 24px;
        letter-spacing: 1px;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 18px;
        font-weight: 700;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #FF7600;

    }

        
    /* Mobile styles */
    @media only screen and (max-device-width: 480px), only screen and (max-width: 480px) {
    
    .content-table {
      width: 90%;
      height: 100%;
      border: 0;
         border-spacing: 0px; 
      padding: 0px;

    }

    .td-logo {
      padding-top: 30px;
      padding-bottom: 30px;
    }

    .td-first {
      padding-top: 10px;
      padding-left: 20px;
      padding-right: 20px;
    }

    .td-second {
      padding-top: 20px; 
      padding-left: 20px; 
      padding-right: 20px; 
      padding-bottom: 40px
    }


    }


  </style>

</head>
<body class="body">
  

  <table width="100%" cellspacing="0" cellpadding="0">
    <tr>
      <td align="center" valign="top">
        
      
        <table class="content-table">
          <tr>
            <td class="td-logo" align="center" valign="top">
              <img src="https://www.hapahapa.app/emails/logo_hapahapa_text.svg">
            </td>
          </tr>
          <tr class="tr-white">
            <td class="td-first" align="left" valign="top">
              <h1>HAPAHAPA | E-Mail Verification</h1>
              <p style="margin-bottom: 6px;"><strong></strong></p>
              <p>Hi there! Your token is expired, please try it again...
              </p>
            </td>
          </tr>

        

          <tr>
            <td align="left" valign="top" style="padding-top: 10px; padding-bottom: 40px;">
              <p class="text-white"><a class="white" href="https://hapahapa.app">© HAPAHAPA</a> | <a class="white" href="#">Imprint</a> | <a class="white" href="#">Data protection</a></p>
            </td>
          </tr>

        </table>

      </td>
    </tr>
  </table>

</body>
</html>`;
export const verifyNoES = `<!DOCTYPE html>
<html lang="es">
<head>
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <meta name="color-scheme" content="light only">
  <meta name="supported-color-schemes" content="light only">
  <link rel="preconnect" href="https://fonts.gstatic.com">
  <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap" rel="stylesheet">
  <title>HAPAHAPA | E-Mail Verification</title>

  <style type="text/css" media="screen">

    :root {
    color-scheme: light only;
    supported-color-schemes: light only;
    }

    body { 
      padding:0 !important; 
        margin: 0 auto !important;
      display:block !important; 
      min-width:100% !important; 
      width: 100% !important; 
        background: url('https://www.hapahapa.app/emails/bg_email.jpg');
        background-size: auto;
         font-family: "Montserrat", sans-serif;
    }

    h1 {
        font-size: 30px;
        line-height: 40px;
        font-weight: 700;
    }


    p {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        margin: 0px;
        padding: 0px;
    }

    a {
      color:  #000;
    }


    a.white {
        font-size: 14px;
      color:  #FFF;
  
    }


    .content-table {
      width: 80%;
      height: 100%;
      border: 0;
         border-spacing: 0px; 
      padding: 0px;
    }


    .tr-white {
      background-color: #FFF;
      padding:0px;
    }


    .td-logo {
      padding-top: 40px;
      padding-bottom: 40px;
    }


    .td-first {
      padding-top: 20px;
      padding-left: 40px;
      padding-right: 40px;
    }


    .td-second {
      padding-top: 20px; 
      padding-left: 40px; 
      padding-right: 40px; 
      padding-bottom: 40px
    }

    .text-white {
      color:  #FFF;
    }

    .cta-orange {
        -webkit-transition-duration: 0.4s; /* Safari */
        transition-duration: 0.4s;
        background-color: #FF7600;
        padding-top: 18px;
        padding-left: 30px;
        padding-bottom: 18px;
        padding-right: 30px;
        color:  #FFF;
        line-height: 24px;
        letter-spacing: 1px;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 18px;
        font-weight: 700;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #FF7600;

    }

        
    /* Mobile styles */
    @media only screen and (max-device-width: 480px), only screen and (max-width: 480px) {
    
    .content-table {
      width: 90%;
      height: 100%;
      border: 0;
         border-spacing: 0px; 
      padding: 0px;

    }

    .td-logo {
      padding-top: 30px;
      padding-bottom: 30px;
    }

    .td-first {
      padding-top: 10px;
      padding-left: 20px;
      padding-right: 20px;
    }

    .td-second {
      padding-top: 20px; 
      padding-left: 20px; 
      padding-right: 20px; 
      padding-bottom: 40px
    }


    }


  </style>

</head>
<body class="body">
  

  <table width="100%" cellspacing="0" cellpadding="0">
    <tr>
      <td align="center" valign="top">
        
      
        <table class="content-table">
          <tr>
            <td class="td-logo" align="center" valign="top">
              <img src="https://www.hapahapa.app/emails/logo_hapahapa_text.svg">
            </td>
          </tr>
          <tr class="tr-white">
            <td class="td-first" align="left" valign="top">
              <h1>HAPAHAPA | Verificacion de email</h1>
              <p style="margin-bottom: 6px;"><strong></strong></p>
              <p>¡Hola! Tu token ha caducado, inténtalo de nuevo...
              </p>
            </td>
          </tr>

        

          <tr>
            <td align="left" valign="top" style="padding-top: 10px; padding-bottom: 40px;">
              <p class="text-white"><a class="white" href="https://hapahapa.app">© HAPAHAPA</a> | <a class="white" href="#">Imprint</a> | <a class="white" href="#">Data protection</a></p>
            </td>
          </tr>

        </table>

      </td>
    </tr>
  </table>

</body>
</html>`;
export const verifyYesDE = `<!DOCTYPE html>
<html lang="de">
<head>
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <meta name="color-scheme" content="light only">
  <meta name="supported-color-schemes" content="light only">
  <link rel="preconnect" href="https://fonts.gstatic.com">
  <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap" rel="stylesheet">
  <title>HAPAHAPA | E-Mail Verifizierung<</title>

  <style type="text/css" media="screen">

    :root {
    color-scheme: light only;
    supported-color-schemes: light only;
    }


    body { 
      padding:0 !important; 
        margin: 0 auto !important;
      display:block !important; 
      min-width:100% !important; 
      width: 100% !important; 
        background: url('https://www.hapahapa.app/emails/bg_email.jpg');
        background-size: auto;
         font-family: "Montserrat", sans-serif;
    }

    h1 {
        font-size: 30px;
        line-height: 40px;
        font-weight: 700;
    }


    p {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        margin: 0px;
        padding: 0px;
    }

    a {
      color:  #000;
    }


    a.white {
        font-size: 14px;
      color:  #FFF;
  
    }


    .content-table {
      width: 80%;
      height: 100%;
      border: 0;
         border-spacing: 0px; 
      padding: 0px;
    }


    .tr-white {
      background-color: #FFF;
      padding:0px;
    }


    .td-logo {
      padding-top: 40px;
      padding-bottom: 40px;
    }


    .td-first {
      padding-top: 20px;
      padding-left: 40px;
      padding-right: 40px;
    }


    .td-second {
      padding-top: 20px; 
      padding-left: 40px; 
      padding-right: 40px; 
      padding-bottom: 40px
    }

    .text-white {
      color:  #FFF;
    }

    .cta-orange {
        -webkit-transition-duration: 0.4s; /* Safari */
        transition-duration: 0.4s;
        background-color: #FF7600;
        padding-top: 18px;
        padding-left: 30px;
        padding-bottom: 18px;
        padding-right: 30px;
        color:  #FFF;
        line-height: 24px;
        letter-spacing: 1px;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 18px;
        font-weight: 700;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #FF7600;

    }

        
    /* Mobile styles */
    @media only screen and (max-device-width: 480px), only screen and (max-width: 480px) {
    
    .content-table {
      width: 90%;
      height: 100%;
      border: 0;
         border-spacing: 0px; 
      padding: 0px;

    }

    .td-logo {
      padding-top: 30px;
      padding-bottom: 30px;
    }

    .td-first {
      padding-top: 10px;
      padding-left: 20px;
      padding-right: 20px;
    }

    .td-second {
      padding-top: 20px; 
      padding-left: 20px; 
      padding-right: 20px; 
      padding-bottom: 40px
    }


    }


  </style>

</head>
<body class="body">
  
  <table width="100%" cellspacing="0" cellpadding="0">
    <tr>
      <td align="center" valign="top">
        

        <table class="content-table">
          <tr>
            <td class="td-logo" align="center" valign="top">
              <img src="https://www.hapahapa.app/emails/logo_hapahapa_text.svg">
            </td>
          </tr>
          <tr class="tr-white">
            <td class="td-first" align="left" valign="top">
              <h1>HAPAHAPA | E-Mail Verifizierung</h1>
              <p style="margin-bottom: 6px;"><strong></strong></p>
              <p>Hallo! Deine Email wurde verifiziert, viel Spass beim Benutzen der App
              </p>
            </td>
          </tr>

          <tr>
            <td align="left" valign="top" style="padding-top: 10px; padding-bottom: 40px;">
              <p class="text-white"><a class="white" href="https://hapahapa.app">© HAPAHAPA</a> | <a class="white" href="#">Impressum</a> | <a class="white" href="#">Datenschutz</a></p>
            </td>
          </tr>

        </table>

      </td>
    </tr>
  </table>

</body>
</html>`;
export const verifyYesEN = `<!DOCTYPE html>
<html lang="en">
<head>
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <meta name="color-scheme" content="light only">
  <meta name="supported-color-schemes" content="light only">
  <link rel="preconnect" href="https://fonts.gstatic.com">
  <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap" rel="stylesheet">
  <title>HAPAHAPA | E-Mail Verification</title>

  <style type="text/css" media="screen">

    :root {
    color-scheme: light only;
    supported-color-schemes: light only;
    }

    body { 
      padding:0 !important; 
        margin: 0 auto !important;
      display:block !important; 
      min-width:100% !important; 
      width: 100% !important; 
        background: url('https://www.hapahapa.app/emails/bg_email.jpg');
        background-size: auto;
         font-family: "Montserrat", sans-serif;
    }

    h1 {
        font-size: 30px;
        line-height: 40px;
        font-weight: 700;
    }


    p {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        margin: 0px;
        padding: 0px;
    }

    a {
      color:  #000;
    }


    a.white {
        font-size: 14px;
      color:  #FFF;
  
    }


    .content-table {
      width: 80%;
      height: 100%;
      border: 0;
         border-spacing: 0px; 
      padding: 0px;
    }


    .tr-white {
      background-color: #FFF;
      padding:0px;
    }


    .td-logo {
      padding-top: 40px;
      padding-bottom: 40px;
    }


    .td-first {
      padding-top: 20px;
      padding-left: 40px;
      padding-right: 40px;
    }


    .td-second {
      padding-top: 20px; 
      padding-left: 40px; 
      padding-right: 40px; 
      padding-bottom: 40px
    }

    .text-white {
      color:  #FFF;
    }

    .cta-orange {
        -webkit-transition-duration: 0.4s; /* Safari */
        transition-duration: 0.4s;
        background-color: #FF7600;
        padding-top: 18px;
        padding-left: 30px;
        padding-bottom: 18px;
        padding-right: 30px;
        color:  #FFF;
        line-height: 24px;
        letter-spacing: 1px;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 18px;
        font-weight: 700;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #FF7600;

    }

        
    /* Mobile styles */
    @media only screen and (max-device-width: 480px), only screen and (max-width: 480px) {
    
    .content-table {
      width: 90%;
      height: 100%;
      border: 0;
         border-spacing: 0px; 
      padding: 0px;

    }

    .td-logo {
      padding-top: 30px;
      padding-bottom: 30px;
    }

    .td-first {
      padding-top: 10px;
      padding-left: 20px;
      padding-right: 20px;
    }

    .td-second {
      padding-top: 20px; 
      padding-left: 20px; 
      padding-right: 20px; 
      padding-bottom: 40px
    }


    }


  </style>

</head>
<body class="body">
  

  <table width="100%" cellspacing="0" cellpadding="0">
    <tr>
      <td align="center" valign="top">
        
      
        <table class="content-table">
          <tr>
            <td class="td-logo" align="center" valign="top">
              <img src="https://www.hapahapa.app/emails/logo_hapahapa_text.svg">
            </td>
          </tr>
          <tr class="tr-white">
            <td class="td-first" align="left" valign="top">
              <h1>HAPAHAPA | E-Mail Verification</h1>
              <p style="margin-bottom: 6px;"><strong></strong></p>
              <p>Hi there! Your Email is verified, have fun by using the app...
              </p>
            </td>
          </tr>

        

          <tr>
            <td align="left" valign="top" style="padding-top: 10px; padding-bottom: 40px;">
              <p class="text-white"><a class="white" href="https://hapahapa.app">© HAPAHAPA</a> | <a class="white" href="#">Imprint</a> | <a class="white" href="#">Data protection</a></p>
            </td>
          </tr>

        </table>

      </td>
    </tr>
  </table>

</body>
</html>`;
export const verifyYesES = `<!DOCTYPE html>
<html lang="es">
<head>
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <meta name="color-scheme" content="light only">
  <meta name="supported-color-schemes" content="light only">
  <link rel="preconnect" href="https://fonts.gstatic.com">
  <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap" rel="stylesheet">
  <title>HAPAHAPA | E-Mail Verification</title>

  <style type="text/css" media="screen">

    :root {
    color-scheme: light only;
    supported-color-schemes: light only;
    }

    body { 
      padding:0 !important; 
        margin: 0 auto !important;
      display:block !important; 
      min-width:100% !important; 
      width: 100% !important; 
        background: url('https://www.hapahapa.app/emails/bg_email.jpg');
        background-size: auto;
         font-family: "Montserrat", sans-serif;
    }

    h1 {
        font-size: 30px;
        line-height: 40px;
        font-weight: 700;
    }


    p {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        margin: 0px;
        padding: 0px;
    }

    a {
      color:  #000;
    }


    a.white {
        font-size: 14px;
      color:  #FFF;
  
    }


    .content-table {
      width: 80%;
      height: 100%;
      border: 0;
         border-spacing: 0px; 
      padding: 0px;
    }


    .tr-white {
      background-color: #FFF;
      padding:0px;
    }


    .td-logo {
      padding-top: 40px;
      padding-bottom: 40px;
    }


    .td-first {
      padding-top: 20px;
      padding-left: 40px;
      padding-right: 40px;
    }


    .td-second {
      padding-top: 20px; 
      padding-left: 40px; 
      padding-right: 40px; 
      padding-bottom: 40px
    }

    .text-white {
      color:  #FFF;
    }

    .cta-orange {
        -webkit-transition-duration: 0.4s; /* Safari */
        transition-duration: 0.4s;
        background-color: #FF7600;
        padding-top: 18px;
        padding-left: 30px;
        padding-bottom: 18px;
        padding-right: 30px;
        color:  #FFF;
        line-height: 24px;
        letter-spacing: 1px;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 18px;
        font-weight: 700;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #FF7600;

    }

        
    /* Mobile styles */
    @media only screen and (max-device-width: 480px), only screen and (max-width: 480px) {
    
    .content-table {
      width: 90%;
      height: 100%;
      border: 0;
         border-spacing: 0px; 
      padding: 0px;

    }

    .td-logo {
      padding-top: 30px;
      padding-bottom: 30px;
    }

    .td-first {
      padding-top: 10px;
      padding-left: 20px;
      padding-right: 20px;
    }

    .td-second {
      padding-top: 20px; 
      padding-left: 20px; 
      padding-right: 20px; 
      padding-bottom: 40px
    }


    }


  </style>

</head>
<body class="body">
  

  <table width="100%" cellspacing="0" cellpadding="0">
    <tr>
      <td align="center" valign="top">
        
      
        <table class="content-table">
          <tr>
            <td class="td-logo" align="center" valign="top">
              <img src="https://www.hapahapa.app/emails/logo_hapahapa_text.svg">
            </td>
          </tr>
          <tr class="tr-white">
            <td class="td-first" align="left" valign="top">
              <h1>HAPAHAPA | Verificacion de email</h1>
              <p style="margin-bottom: 6px;"><strong></strong></p>
              <p>¡Hola! Su correo electrónico está verificado, diviértase usando la aplicación...
              </p>
            </td>
          </tr>

        

          <tr>
            <td align="left" valign="top" style="padding-top: 10px; padding-bottom: 40px;">
              <p class="text-white"><a class="white" href="https://hapahapa.app">© HAPAHAPA</a> | <a class="white" href="#">Imprint</a> | <a class="white" href="#">Data protection</a></p>
            </td>
          </tr>

        </table>

      </td>
    </tr>
  </table>

</body>
</html>`;
export const resetPwdErrorDE = `<!DOCTYPE html>
<html lang="de">
<head>
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <meta name="color-scheme" content="light only">
  <meta name="supported-color-schemes" content="light only">
  <link rel="preconnect" href="https://fonts.gstatic.com">
  <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap" rel="stylesheet">
  <title>HAPAHAPA | Passwort vergessen</title>

  <style type="text/css" media="screen">
  
    :root {
    color-scheme: light only;
    supported-color-schemes: light only;
    }

    body { 
      padding:0 !important; 
        margin: 0 auto !important;
      display:block !important; 
      min-width:100% !important; 
      width: 100% !important; 
        background: url('https://www.hapahapa.app/emails/bg_email.jpg');
        background-size: auto;
         font-family: "Montserrat", sans-serif;
    }

    h1 {
        font-size: 30px;
        line-height: 40px;
        font-weight: 700;
    }


    p {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        margin: 0px;
        padding: 0px;
    }

    a {
      color:  #000;
    }


    a.white {
        font-size: 14px;
      color:  #FFF;
  
    }


    .content-table {
      width: 80%;
      height: 100%;
      border: 0;
         border-spacing: 0px; 
      padding: 0px;
    }


    .tr-white {
      background-color: #FFF;
      padding:0px;
    }


    .td-logo {
      padding-top: 40px;
      padding-bottom: 40px;
    }


    .td-first {
      padding-top: 20px;
      padding-left: 40px;
      padding-right: 40px;
      padding-bottom: 40px
    }


    .text-white {
      color:  #FFF;
    }

        
    /* Mobile styles */
    @media only screen and (max-device-width: 480px), only screen and (max-width: 480px) {
    
    .content-table {
      width: 90%;
      height: 100%;
      border: 0;
         border-spacing: 0px; 
      padding: 0px;

    }

    .td-logo {
      padding-top: 30px;
      padding-bottom: 30px;
    }

    .td-first {
      padding-top: 10px;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 40px
    }



    }


  </style>

</head>
<body class="body">
  
  <!-- WRAPPER -->
  <table width="100%" cellspacing="0" cellpadding="0">
    <tr>
      <td align="center" valign="top">
        
        <!-- CONTENT TABLE -->
        <table class="content-table">
          <tr>
            <td class="td-logo" align="center" valign="top">
              <img src="https://www.hapahapa.app/emails/logo_hapahapa_text.svg">
            </td>
          </tr>
          <tr class="tr-white">
            <td class="td-first" align="left" valign="top">
              <h1>Neues Passwort angefordert</h1>
              <p>Sorry, da ist wohl etwas schief gelaufen, bitte frage erneut ein neues Passwort an. Vielen Dank!
              </p>
            </td>
          </tr>

          <tr>
            <td align="left" valign="top" style="padding-top: 10px; padding-bottom: 40px;">
              <p class="text-white"><a class="white" href="https://hapahapa.app">© HAPAHAPA</a> | <a class="white" href="#">Impressum</a> | <a class="white" href="#">Datenschutz</a></p>
            </td>
          </tr>

        </table>

      </td>
    </tr>
  </table>

</body>
</html>`;
export const resetPwdErrorEN = `<!DOCTYPE html>
<html lang="en">
<head>
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <meta name="color-scheme" content="light only">
  <meta name="supported-color-schemes" content="light only">
  <link rel="preconnect" href="https://fonts.gstatic.com">
  <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap" rel="stylesheet">
  <title>HAPAHAPA | Forgot Password</title>

  <style type="text/css" media="screen">
  
    :root {
    color-scheme: light only;
    supported-color-schemes: light only;
    }

    body { 
      padding:0 !important; 
        margin: 0 auto !important;
      display:block !important; 
      min-width:100% !important; 
      width: 100% !important; 
        background: url('https://www.hapahapa.app/emails/bg_email.jpg');
        background-size: auto;
         font-family: "Montserrat", sans-serif;
    }

    h1 {
        font-size: 30px;
        line-height: 40px;
        font-weight: 700;
    }


    p {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        margin: 0px;
        padding: 0px;
    }

    a {
      color:  #000;
    }


    a.white {
        font-size: 14px;
      color:  #FFF;
  
    }


    .content-table {
      width: 80%;
      height: 100%;
      border: 0;
         border-spacing: 0px; 
      padding: 0px;
    }


    .tr-white {
      background-color: #FFF;
      padding:0px;
    }


    .td-logo {
      padding-top: 40px;
      padding-bottom: 40px;
    }


    .td-first {
      padding-top: 20px;
      padding-left: 40px;
      padding-right: 40px;
      padding-bottom: 40px
    }


    .text-white {
      color:  #FFF;
    }

        
    /* Mobile styles */
    @media only screen and (max-device-width: 480px), only screen and (max-width: 480px) {
    
    .content-table {
      width: 90%;
      height: 100%;
      border: 0;
         border-spacing: 0px; 
      padding: 0px;

    }

    .td-logo {
      padding-top: 30px;
      padding-bottom: 30px;
    }

    .td-first {
      padding-top: 10px;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 40px
    }



    }


  </style>

</head>
<body class="body">
  
  <!-- WRAPPER -->
  <table width="100%" cellspacing="0" cellpadding="0">
    <tr>
      <td align="center" valign="top">
        
        <!-- CONTENT TABLE -->
        <table class="content-table">
          <tr>
            <td class="td-logo" align="center" valign="top">
              <img src="https://www.hapahapa.app/emails/logo_hapahapa_text.svg">
            </td>
          </tr>
          <tr class="tr-white">
            <td class="td-first" align="left" valign="top">
              <h1>New password requested</h1>
              <p>Sorry, something must have gone wrong, please request a new password again. Thank you very much!
              </p>
            </td>
          </tr>

          <tr>
            <td align="left" valign="top" style="padding-top: 10px; padding-bottom: 40px;">
              <p class="text-white"><a class="white" href="https://hapahapa.app">© HAPAHAPA</a> | <a class="white" href="#">Impressum</a> | <a class="white" href="#">Datenschutz</a></p>
            </td>
          </tr>

        </table>

      </td>
    </tr>
  </table>

</body>
</html>`;
export const resetPwdErrorES = `<!DOCTYPE html>
<html lang="es">
<head>
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <meta name="color-scheme" content="light only">
  <meta name="supported-color-schemes" content="light only">
  <link rel="preconnect" href="https://fonts.gstatic.com">
  <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap" rel="stylesheet">
  <title>HAPAHAPA | Forgot Password</title>

  <style type="text/css" media="screen">
  
    :root {
    color-scheme: light only;
    supported-color-schemes: light only;
    }

    body { 
      padding:0 !important; 
        margin: 0 auto !important;
      display:block !important; 
      min-width:100% !important; 
      width: 100% !important; 
        background: url('https://www.hapahapa.app/emails/bg_email.jpg');
        background-size: auto;
         font-family: "Montserrat", sans-serif;
    }

    h1 {
        font-size: 30px;
        line-height: 40px;
        font-weight: 700;
    }


    p {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        margin: 0px;
        padding: 0px;
    }

    a {
      color:  #000;
    }


    a.white {
        font-size: 14px;
      color:  #FFF;
  
    }


    .content-table {
      width: 80%;
      height: 100%;
      border: 0;
         border-spacing: 0px; 
      padding: 0px;
    }


    .tr-white {
      background-color: #FFF;
      padding:0px;
    }


    .td-logo {
      padding-top: 40px;
      padding-bottom: 40px;
    }


    .td-first {
      padding-top: 20px;
      padding-left: 40px;
      padding-right: 40px;
      padding-bottom: 40px
    }


    .text-white {
      color:  #FFF;
    }

        
    /* Mobile styles */
    @media only screen and (max-device-width: 480px), only screen and (max-width: 480px) {
    
    .content-table {
      width: 90%;
      height: 100%;
      border: 0;
         border-spacing: 0px; 
      padding: 0px;

    }

    .td-logo {
      padding-top: 30px;
      padding-bottom: 30px;
    }

    .td-first {
      padding-top: 10px;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 40px
    }



    }


  </style>

</head>
<body class="body">
  
  <!-- WRAPPER -->
  <table width="100%" cellspacing="0" cellpadding="0">
    <tr>
      <td align="center" valign="top">
        
        <!-- CONTENT TABLE -->
        <table class="content-table">
          <tr>
            <td class="td-logo" align="center" valign="top">
              <img src="https://www.hapahapa.app/emails/logo_hapahapa_text.svg">
            </td>
          </tr>
          <tr class="tr-white">
            <td class="td-first" align="left" valign="top">
              <h1>Nueva contraseña solicitada</h1>
              <p>Lo sentimos, algo debe haber salido mal, solicita una nueva contraseña nuevamente. ¡Muchas gracias!
              </p>
            </td>
          </tr>

          <tr>
            <td align="left" valign="top" style="padding-top: 10px; padding-bottom: 40px;">
              <p class="text-white"><a class="white" href="https://hapahapa.app">© HAPAHAPA</a> | <a class="white" href="#">Impressum</a> | <a class="white" href="#">Datenschutz</a></p>
            </td>
          </tr>

        </table>

      </td>
    </tr>
  </table>

</body>
</html>`;
export const resetPwdYesDE = `<!DOCTYPE html>
<html lang="de">
<head>
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <meta name="color-scheme" content="light only">
  <meta name="supported-color-schemes" content="light only">
  <link rel="preconnect" href="https://fonts.gstatic.com">
  <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap" rel="stylesheet">
  <title>HAPAHAPA | Passwort vergessen</title>

  <style type="text/css" media="screen">
  
    :root {
    color-scheme: light only;
    supported-color-schemes: light only;
    }

    body { 
      padding:0 !important; 
        margin: 0 auto !important;
      display:block !important; 
      min-width:100% !important; 
      width: 100% !important; 
        background: url('https://www.hapahapa.app/emails/bg_email.jpg');
        background-size: auto;
         font-family: "Montserrat", sans-serif;
    }

    h1 {
        font-size: 30px;
        line-height: 40px;
        font-weight: 700;
    }


    p {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        margin: 0px;
        padding: 0px;
    }

    a {
      color:  #000;
    }


    a.white {
        font-size: 14px;
      color:  #FFF;
  
    }


    .content-table {
      width: 80%;
      height: 100%;
      border: 0;
         border-spacing: 0px; 
      padding: 0px;
    }


    .tr-white {
      background-color: #FFF;
      padding:0px;
    }


    .td-logo {
      padding-top: 40px;
      padding-bottom: 40px;
    }


    .td-first {
      padding-top: 20px;
      padding-left: 40px;
      padding-right: 40px;
      padding-bottom: 40px
    }


    .text-white {
      color:  #FFF;
    }

        
    /* Mobile styles */
    @media only screen and (max-device-width: 480px), only screen and (max-width: 480px) {
    
    .content-table {
      width: 90%;
      height: 100%;
      border: 0;
         border-spacing: 0px; 
      padding: 0px;

    }

    .td-logo {
      padding-top: 30px;
      padding-bottom: 30px;
    }

    .td-first {
      padding-top: 10px;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 40px
    }



    }


  </style>

</head>
<body class="body">
  
  <!-- WRAPPER -->
  <table width="100%" cellspacing="0" cellpadding="0">
    <tr>
      <td align="center" valign="top">
        
        <!-- CONTENT TABLE -->
        <table class="content-table">
          <tr>
            <td class="td-logo" align="center" valign="top">
              <img src="https://www.hapahapa.app/emails/logo_hapahapa_text.svg">
            </td>
          </tr>
          <tr class="tr-white">
            <td class="td-first" align="left" valign="top">
              <h1>Neues Passwort angefordert</h1>
              <p>Alles klar, Dein Passwort wurde erfolgreich geaendert
              </p>
            </td>
          </tr>

          <tr>
            <td align="left" valign="top" style="padding-top: 10px; padding-bottom: 40px;">
              <p class="text-white"><a class="white" href="https://hapahapa.app">© HAPAHAPA</a> | <a class="white" href="#">Impressum</a> | <a class="white" href="#">Datenschutz</a></p>
            </td>
          </tr>

        </table>

      </td>
    </tr>
  </table>

</body>
</html>`;
export const resetPwdYesEN = `<!DOCTYPE html>
<html lang="en">
<head>
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <meta name="color-scheme" content="light only">
  <meta name="supported-color-schemes" content="light only">
  <link rel="preconnect" href="https://fonts.gstatic.com">
  <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap" rel="stylesheet">
  <title>HAPAHAPA | Forgot Password</title>

  <style type="text/css" media="screen">
  
    :root {
    color-scheme: light only;
    supported-color-schemes: light only;
    }

    body { 
      padding:0 !important; 
        margin: 0 auto !important;
      display:block !important; 
      min-width:100% !important; 
      width: 100% !important; 
        background: url('https://www.hapahapa.app/emails/bg_email.jpg');
        background-size: auto;
         font-family: "Montserrat", sans-serif;
    }

    h1 {
        font-size: 30px;
        line-height: 40px;
        font-weight: 700;
    }


    p {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        margin: 0px;
        padding: 0px;
    }

    a {
      color:  #000;
    }


    a.white {
        font-size: 14px;
      color:  #FFF;
  
    }


    .content-table {
      width: 80%;
      height: 100%;
      border: 0;
         border-spacing: 0px; 
      padding: 0px;
    }


    .tr-white {
      background-color: #FFF;
      padding:0px;
    }


    .td-logo {
      padding-top: 40px;
      padding-bottom: 40px;
    }


    .td-first {
      padding-top: 20px;
      padding-left: 40px;
      padding-right: 40px;
      padding-bottom: 40px
    }


    .text-white {
      color:  #FFF;
    }

        
    /* Mobile styles */
    @media only screen and (max-device-width: 480px), only screen and (max-width: 480px) {
    
    .content-table {
      width: 90%;
      height: 100%;
      border: 0;
         border-spacing: 0px; 
      padding: 0px;

    }

    .td-logo {
      padding-top: 30px;
      padding-bottom: 30px;
    }

    .td-first {
      padding-top: 10px;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 40px
    }



    }


  </style>

</head>
<body class="body">
  
  <!-- WRAPPER -->
  <table width="100%" cellspacing="0" cellpadding="0">
    <tr>
      <td align="center" valign="top">
        
        <!-- CONTENT TABLE -->
        <table class="content-table">
          <tr>
            <td class="td-logo" align="center" valign="top">
              <img src="https://www.hapahapa.app/emails/logo_hapahapa_text.svg">
            </td>
          </tr>
          <tr class="tr-white">
            <td class="td-first" align="left" valign="top">
              <h1>New password requested</h1>
              <p>Alright, your passwort has changed
              </p>
            </td>
          </tr>

          <tr>
            <td align="left" valign="top" style="padding-top: 10px; padding-bottom: 40px;">
              <p class="text-white"><a class="white" href="https://hapahapa.app">© HAPAHAPA</a> | <a class="white" href="#">Impressum</a> | <a class="white" href="#">Datenschutz</a></p>
            </td>
          </tr>

        </table>

      </td>
    </tr>
  </table>

</body>
</html>`;
export const resetPwdYesES = `<!DOCTYPE html>
<html lang="en">
<head>
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <meta name="color-scheme" content="light only">
  <meta name="supported-color-schemes" content="light only">
  <link rel="preconnect" href="https://fonts.gstatic.com">
  <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap" rel="stylesheet">
  <title>HAPAHAPA | Forgot Password</title>

  <style type="text/css" media="screen">
  
    :root {
    color-scheme: light only;
    supported-color-schemes: light only;
    }

    body { 
      padding:0 !important; 
        margin: 0 auto !important;
      display:block !important; 
      min-width:100% !important; 
      width: 100% !important; 
        background: url('https://www.hapahapa.app/emails/bg_email.jpg');
        background-size: auto;
         font-family: "Montserrat", sans-serif;
    }

    h1 {
        font-size: 30px;
        line-height: 40px;
        font-weight: 700;
    }


    p {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        margin: 0px;
        padding: 0px;
    }

    a {
      color:  #000;
    }


    a.white {
        font-size: 14px;
      color:  #FFF;
  
    }


    .content-table {
      width: 80%;
      height: 100%;
      border: 0;
         border-spacing: 0px; 
      padding: 0px;
    }


    .tr-white {
      background-color: #FFF;
      padding:0px;
    }


    .td-logo {
      padding-top: 40px;
      padding-bottom: 40px;
    }


    .td-first {
      padding-top: 20px;
      padding-left: 40px;
      padding-right: 40px;
      padding-bottom: 40px
    }


    .text-white {
      color:  #FFF;
    }

        
    /* Mobile styles */
    @media only screen and (max-device-width: 480px), only screen and (max-width: 480px) {
    
    .content-table {
      width: 90%;
      height: 100%;
      border: 0;
         border-spacing: 0px; 
      padding: 0px;

    }

    .td-logo {
      padding-top: 30px;
      padding-bottom: 30px;
    }

    .td-first {
      padding-top: 10px;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 40px
    }



    }


  </style>

</head>
<body class="body">
  
  <!-- WRAPPER -->
  <table width="100%" cellspacing="0" cellpadding="0">
    <tr>
      <td align="center" valign="top">
        
        <!-- CONTENT TABLE -->
        <table class="content-table">
          <tr>
            <td class="td-logo" align="center" valign="top">
              <img src="https://www.hapahapa.app/emails/logo_hapahapa_text.svg">
            </td>
          </tr>
          <tr class="tr-white">
            <td class="td-first" align="left" valign="top">
              <h1>Nueva contraseña solicitada</h1>
              <p>Bien, tu contraseña ha cambiado
              </p>
            </td>
          </tr>

          <tr>
            <td align="left" valign="top" style="padding-top: 10px; padding-bottom: 40px;">
              <p class="text-white"><a class="white" href="https://hapahapa.app">© HAPAHAPA</a> | <a class="white" href="#">Impressum</a> | <a class="white" href="#">Datenschutz</a></p>
            </td>
          </tr>

        </table>

      </td>
    </tr>
  </table>

</body>
</html>`;
export const resetPwdNoDE = `<!DOCTYPE html>
<html lang="de">
<head>
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <meta name="color-scheme" content="light only">
  <meta name="supported-color-schemes" content="light only">
  <link rel="preconnect" href="https://fonts.gstatic.com">
  <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap" rel="stylesheet">
  <title>HAPAHAPA | Passwort vergessen</title>

  <style type="text/css" media="screen">
  
    :root {
    color-scheme: light only;
    supported-color-schemes: light only;
    }

    body { 
      padding:0 !important; 
        margin: 0 auto !important;
      display:block !important; 
      min-width:100% !important; 
      width: 100% !important; 
        background: url('https://www.hapahapa.app/emails/bg_email.jpg');
        background-size: auto;
         font-family: "Montserrat", sans-serif;
    }

    h1 {
        font-size: 30px;
        line-height: 40px;
        font-weight: 700;
    }


    p {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        margin: 0px;
        padding: 0px;
    }

    a {
      color:  #000;
    }


    a.white {
        font-size: 14px;
      color:  #FFF;
  
    }


    .content-table {
      width: 80%;
      height: 100%;
      border: 0;
         border-spacing: 0px; 
      padding: 0px;
    }


    .tr-white {
      background-color: #FFF;
      padding:0px;
    }


    .td-logo {
      padding-top: 40px;
      padding-bottom: 40px;
    }


    .td-first {
      padding-top: 20px;
      padding-left: 40px;
      padding-right: 40px;
      padding-bottom: 40px
    }


    .text-white {
      color:  #FFF;
    }

        
    /* Mobile styles */
    @media only screen and (max-device-width: 480px), only screen and (max-width: 480px) {
    
    .content-table {
      width: 90%;
      height: 100%;
      border: 0;
         border-spacing: 0px; 
      padding: 0px;

    }

    .td-logo {
      padding-top: 30px;
      padding-bottom: 30px;
    }

    .td-first {
      padding-top: 10px;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 40px
    }



    }


  </style>

</head>
<body class="body">
  
  <!-- WRAPPER -->
  <table width="100%" cellspacing="0" cellpadding="0">
    <tr>
      <td align="center" valign="top">
        
        <!-- CONTENT TABLE -->
        <table class="content-table">
          <tr>
            <td class="td-logo" align="center" valign="top">
              <img src="https://www.hapahapa.app/emails/logo_hapahapa_text.svg">
            </td>
          </tr>
          <tr class="tr-white">
            <td class="td-first" align="left" valign="top">
              <h1>Neues Passwort angefordert</h1>
              <p>Sorry, da ist wohl etwas schief gelaufen, bitte frage erneut ein neues Passwort an. Vielen Dank!
              </p>
            </td>
          </tr>

          <tr>
            <td align="left" valign="top" style="padding-top: 10px; padding-bottom: 40px;">
              <p class="text-white"><a class="white" href="https://hapahapa.app">© HAPAHAPA</a> | <a class="white" href="#">Impressum</a> | <a class="white" href="#">Datenschutz</a></p>
            </td>
          </tr>

        </table>

      </td>
    </tr>
  </table>

</body>
</html>`;
export const resetPwdNoEN = `<!DOCTYPE html>
<html lang="en">
<head>
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <meta name="color-scheme" content="light only">
  <meta name="supported-color-schemes" content="light only">
  <link rel="preconnect" href="https://fonts.gstatic.com">
  <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap" rel="stylesheet">
  <title>HAPAHAPA | Forgot Password</title>

  <style type="text/css" media="screen">
  
    :root {
    color-scheme: light only;
    supported-color-schemes: light only;
    }

    body { 
      padding:0 !important; 
        margin: 0 auto !important;
      display:block !important; 
      min-width:100% !important; 
      width: 100% !important; 
        background: url('https://www.hapahapa.app/emails/bg_email.jpg');
        background-size: auto;
         font-family: "Montserrat", sans-serif;
    }

    h1 {
        font-size: 30px;
        line-height: 40px;
        font-weight: 700;
    }


    p {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        margin: 0px;
        padding: 0px;
    }

    a {
      color:  #000;
    }


    a.white {
        font-size: 14px;
      color:  #FFF;
  
    }


    .content-table {
      width: 80%;
      height: 100%;
      border: 0;
         border-spacing: 0px; 
      padding: 0px;
    }


    .tr-white {
      background-color: #FFF;
      padding:0px;
    }


    .td-logo {
      padding-top: 40px;
      padding-bottom: 40px;
    }


    .td-first {
      padding-top: 20px;
      padding-left: 40px;
      padding-right: 40px;
      padding-bottom: 40px
    }


    .text-white {
      color:  #FFF;
    }

        
    /* Mobile styles */
    @media only screen and (max-device-width: 480px), only screen and (max-width: 480px) {
    
    .content-table {
      width: 90%;
      height: 100%;
      border: 0;
         border-spacing: 0px; 
      padding: 0px;

    }

    .td-logo {
      padding-top: 30px;
      padding-bottom: 30px;
    }

    .td-first {
      padding-top: 10px;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 40px
    }



    }


  </style>

</head>
<body class="body">
  
  <!-- WRAPPER -->
  <table width="100%" cellspacing="0" cellpadding="0">
    <tr>
      <td align="center" valign="top">
        
        <!-- CONTENT TABLE -->
        <table class="content-table">
          <tr>
            <td class="td-logo" align="center" valign="top">
              <img src="https://www.hapahapa.app/emails/logo_hapahapa_text.svg">
            </td>
          </tr>
          <tr class="tr-white">
            <td class="td-first" align="left" valign="top">
              <h1>New password requested</h1>
              <p>Sorry, something must have gone wrong, please request a new password again. Thank you very much!
              </p>
            </td>
          </tr>

          <tr>
            <td align="left" valign="top" style="padding-top: 10px; padding-bottom: 40px;">
              <p class="text-white"><a class="white" href="https://hapahapa.app">© HAPAHAPA</a> | <a class="white" href="#">Impressum</a> | <a class="white" href="#">Datenschutz</a></p>
            </td>
          </tr>

        </table>

      </td>
    </tr>
  </table>

</body>
</html>`;
export const resetPwdNoES = `<!DOCTYPE html>
<html lang="es">
<head>
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <meta name="color-scheme" content="light only">
  <meta name="supported-color-schemes" content="light only">
  <link rel="preconnect" href="https://fonts.gstatic.com">
  <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap" rel="stylesheet">
  <title>HAPAHAPA | Forgot Password</title>

  <style type="text/css" media="screen">
  
    :root {
    color-scheme: light only;
    supported-color-schemes: light only;
    }

    body { 
      padding:0 !important; 
        margin: 0 auto !important;
      display:block !important; 
      min-width:100% !important; 
      width: 100% !important; 
        background: url('https://www.hapahapa.app/emails/bg_email.jpg');
        background-size: auto;
         font-family: "Montserrat", sans-serif;
    }

    h1 {
        font-size: 30px;
        line-height: 40px;
        font-weight: 700;
    }


    p {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        margin: 0px;
        padding: 0px;
    }

    a {
      color:  #000;
    }


    a.white {
        font-size: 14px;
      color:  #FFF;
  
    }


    .content-table {
      width: 80%;
      height: 100%;
      border: 0;
         border-spacing: 0px; 
      padding: 0px;
    }


    .tr-white {
      background-color: #FFF;
      padding:0px;
    }


    .td-logo {
      padding-top: 40px;
      padding-bottom: 40px;
    }


    .td-first {
      padding-top: 20px;
      padding-left: 40px;
      padding-right: 40px;
      padding-bottom: 40px
    }


    .text-white {
      color:  #FFF;
    }

        
    /* Mobile styles */
    @media only screen and (max-device-width: 480px), only screen and (max-width: 480px) {
    
    .content-table {
      width: 90%;
      height: 100%;
      border: 0;
         border-spacing: 0px; 
      padding: 0px;

    }

    .td-logo {
      padding-top: 30px;
      padding-bottom: 30px;
    }

    .td-first {
      padding-top: 10px;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 40px
    }



    }


  </style>

</head>
<body class="body">
  
  <!-- WRAPPER -->
  <table width="100%" cellspacing="0" cellpadding="0">
    <tr>
      <td align="center" valign="top">
        
        <!-- CONTENT TABLE -->
        <table class="content-table">
          <tr>
            <td class="td-logo" align="center" valign="top">
              <img src="https://www.hapahapa.app/emails/logo_hapahapa_text.svg">
            </td>
          </tr>
          <tr class="tr-white">
            <td class="td-first" align="left" valign="top">
              <h1>Nueva contraseña solicitada</h1>
              <p>Lo sentimos, algo debe haber salido mal, solicita una nueva contraseña nuevamente. ¡Muchas gracias!
              </p>
            </td>
          </tr>

          <tr>
            <td align="left" valign="top" style="padding-top: 10px; padding-bottom: 40px;">
              <p class="text-white"><a class="white" href="https://hapahapa.app">© HAPAHAPA</a> | <a class="white" href="#">Impressum</a> | <a class="white" href="#">Datenschutz</a></p>
            </td>
          </tr>

        </table>

      </td>
    </tr>
  </table>

</body>
</html>`;
