import React from "react";
import RecoverEmail from "./RecoverEmail";
import ResetPassword from "./ResetPassword";
import VerifyEmail from "./VerifyEmail";

import { useParams, useLocation, useHistory } from "react-router-dom";

// http://localhost:3000/action?mode=resetPassword&oobCode=ABC123&apiKey=AIzaSy

// mode - The user management action to be completed
// oobCode - A one-time code, used to identify and verify a request
// apiKey - Your Firebase project's API key, provided for convenience

const Action = (props) => {
  const searchParams = new URLSearchParams(useLocation().search);

  // Get the action to complete.
  const mode = searchParams.get("mode");

  // Get the one-time code from the query parameter.
  const actionCode = searchParams.get("oobCode");
  const lang = searchParams.get("lang");

  // (Optional) Get the API key from the query parameter.
  // const apiKey = props.location.query.apiKey;

  // Handle the user management action.
  switch (mode) {
    case "recoverEmail":
      // Display reset password handler and UI.
      return <RecoverEmail actionCode={actionCode} lang={lang} />;
    case "resetPassword":
      // Display email recovery handler and UI.
      return <ResetPassword actionCode={actionCode} lang={lang} />;
    case "verifyEmail":
      // Display email verification handler and UI.
      return <VerifyEmail actionCode={actionCode} lang={lang} />;
    default:
      // Error: invalid mode.
      useHistory().push("/lost");
      return <></>;
  }
};

export default Action;
