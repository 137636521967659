import React from "react";
import { authRef } from "../reference";
import {
  resetPwdErrorDE,
  resetPwdErrorEN,
  resetPwdErrorES,
  resetPwdNoDE,
  resetPwdNoEN,
  resetPwdNoES,
  resetPwdYesDE,
  resetPwdYesEN,
  resetPwdYesES,
} from "../template";

import "./ResetPassword.css";

const ResetPassword = (props) => {
  const [email, setEmail] = React.useState(null);
  const [newPwd, setNewPwd] = React.useState(null);
  const [validCode, setValidCode] = React.useState(false);
  const [verifiedCode, setVerifiedCode] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [result, setResult] = React.useState(null);

  React.useEffect(() => {
    // Try to apply the email verification code.
    if (props?.actionCode !== null) authRef.languageCode = props.lang;
    authRef.verifyPasswordResetCode(props.actionCode).then(
      (email) => {
        setEmail(email);
        setValidCode(true);
        setVerifiedCode(true);
      },
      (error) => {
        // Invalid or expired action code. Ask user to try to reset the password
        // again.
        console.log("Error", error);
        setValidCode(false);
        setVerifiedCode(true);
        setErrorMessage(error.message);
      }
    );
  }, []);

  const handleResetPassword = () => {
    // Save the new password.
    authRef.confirmPasswordReset(props.actionCode, newPwd).then(
      () => {
        // Password reset has been confirmed and new password updated.
        console.log("PWD changed");
        setResult(true);
      },
      (error) => {
        // Error occurred during confirmation. The code might have expired or the
        // password is too weak.
        console.log("error", error);
        setResult(false);
      }
    );
  };

  const htmlError =
    props?.lang === "de"
      ? resetPwdErrorDE
      : props.lang === "en"
      ? resetPwdErrorEN
      : resetPwdErrorES;

  const htmlResultYes =
    props?.lang === "de"
      ? resetPwdYesDE
      : props.lang === "en"
      ? resetPwdYesEN
      : resetPwdYesES;

  const htmlResultNo =
    props?.lang === "de"
      ? resetPwdNoDE
      : props.lang === "en"
      ? resetPwdNoEN
      : resetPwdNoES;

  if (result === true) {
    return <div dangerouslySetInnerHTML={{ __html: htmlResultYes }} />;
  } else if (result === false) {
    return <div dangerouslySetInnerHTML={{ __html: htmlResultNo }} />;
  }

  // until now now call was done
  if (verifiedCode && validCode) {
    // we can reset the password for this client
    return (
      <body class="body">
        <table width="100%" cellSpacing="0" cellPadding="0">
          <tbody>
            <tr>
              <td align="center" valign="top">
                <table className="content-table">
                  <tbody>
                    <tr>
                      <td className="td-logo" align="center" valign="top">
                        <img src="https://www.hapahapa.app/emails/logo_hapahapa_text.svg" />
                      </td>
                    </tr>

                    <tr className="tr-white">
                      <td className="td-first" align="left" valign="top">
                        <h1>Passwort Zurücksetzen</h1>
                        <p style={{ marginBottom: 6 }}>
                          <strong>
                            Bitte gib ein neues Password fuer {email} ein
                          </strong>
                        </p>
                        <input
                          style={{ width: "100%", height: 24 }}
                          type="password"
                          name="pwd"
                          onChange={(data) => setNewPwd(data.target.value)}
                        />
                      </td>
                    </tr>

                    <tr className="tr-white">
                      <td
                        align="center"
                        valign="top"
                        style={{
                          paddingTop: 40,
                          paddingLeft: 40,
                          paddingRight: 40,
                          paddingBottom: 40,
                        }}
                      >
                        <button
                          className="cta-orange"
                          onClick={() => handleResetPassword()}
                        >
                          Passwort ändern
                        </button>
                      </td>
                    </tr>

                    <tr className="tr-white">
                      <td className="td-second" align="left" valign="top">
                        <p style={{ marginBottom: 6 }}>
                          <strong>Keinen neues Passwort angefordert?</strong>
                        </p>
                        <p>
                          Keine Sorge, vielleicht wurde Deine E-Mail Adresse aus
                          Versehen oder falsch eingetragen. Falls Du also diese
                          Mail nicht zuordnen kannst musst Du nichts tun und
                          kannst diese E-Mail ignorieren.
                        </p>
                      </td>
                    </tr>

                    <tr>
                      <td
                        align="left"
                        valign="top"
                        style={{ paddingTop: 10, paddingBottom: 40 }}
                      >
                        <p className="text-white">
                          <a className="white" href="https://hapahapa.app">
                            © HAPAHAPA
                          </a>{" "}
                          |{" "}
                          <a className="white" href="#">
                            Impressum
                          </a>{" "}
                          |{" "}
                          <a className="white" href="#">
                            Datenschutz
                          </a>
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </body>
    );
  } else {
    // we have issues to reset the pws probably token not valid
    return <div dangerouslySetInnerHTML={{ __html: htmlError }} />;
  }
};

export default ResetPassword;
