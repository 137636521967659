import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/functions";

firebase.initializeApp({
  apiKey: "AIzaSyBKXxrL1JyDoH8ROlxkySTRT2F46NwtVoc",
  authDomain: "hapahapa-697c5.firebaseapp.com",
  databaseURL:
    "https://hapahapa-697c5-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "hapahapa-697c5",
});

export const authRef = firebase.auth();
export const database = firebase.database();
export const functions = firebase.app().functions("europe-west1");
