import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Action from "./components/Action";
import Lost from "./components/Lost";


ReactDOM.render(
  <Router>
    <Switch>
      <Route path="/action" exact component={Action} />
      <Route path="/auth" exact component={Action} />
      <Route path="/" component={Lost} />
    </Switch>
  </Router>,
  document.getElementById("root")
);
